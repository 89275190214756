/* eslint-disable import/no-named-as-default-member */
import firebase from 'firebase/app'
import 'firebase/functions'
import React from 'react'
import { Helmet } from 'react-helmet'
import { updateCookies } from 'utils/cookies'
import { colors } from 'styles/colors'

const browser = typeof window !== 'undefined'
const firebaseConfig = {
  apiKey: 'AIzaSyAnNF_E8zEOwe6rvOtrvwYuYGI3spotesI',
  authDomain: 'phase2-15e76.firebaseapp.com',
  projectId: 'phase2-15e76',
  storageBucket: 'phase2-15e76.appspot.com',
  messagingSenderId: '1047159460019',
  appId: '1:1047159460019:web:15a6ae4d3ff91a9f6fa0c6',
}

if (browser) {
  const robotoFontLink = document.createElement('link')
  robotoFontLink.setAttribute('rel', 'stylesheet')
  robotoFontLink.setAttribute(
    'href',
    'https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap'
  )
  document.head.appendChild(robotoFontLink)
  firebase.initializeApp(firebaseConfig)
  updateCookies()
  process.env.NODE_ENV === 'development' &&
    firebase.functions().useEmulator('localhost', 5001)
}
type MetaProps = {
  title?: string
}

const Meta: React.FC<MetaProps> = props => {
  const title = props.title ? `phase2 • ${props.title}` : `phase2`
  const description =
    'phase2 are dedicated to enabling innovative and aspiring startups access to the most talented individuals to scale their teams, establish their culture and thrive'
  const image = 'https://phase2.web.app/splash.png'
  return (
    <Helmet>
      <html lang="en" />
      <title>{title}</title>
      <meta charSet="utf-8" />
      <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
      <meta name="viewport" content="width=device-width,initial-scale=1" />
      {/* <link rel='icon'  type='image/x-icon' href='/favicon.ico' /> 
      // See gatsby-confing.js
      */}
      <meta name="theme-color" content={colors.secondary.DEFAULT} />
      <meta name="apple-mobile-web-app-capable" content="no" />
      <meta name="apple-mobile-web-app-status-bar-style" content="default" />
      <meta name="apple-mobile-web-app-title" content="phase2" />
      <meta name="msapplication-TileColor" content="#000000" />
      <meta name="description" content={description} />
      <meta name="author" content="phase2" />
      <meta property="og:site_name" content="phase2" />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={image} />
      <meta property="og:image:secure_url" content={image} />
      <meta property="og:type" content="website" />
      {/* <link
        rel='stylesheet'
        href='https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap'
      /> */}
      <link
        rel="preload"
        href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap"
        as="style"
        id="roboto-font-link"
      />
      <noscript
        dangerouslySetInnerHTML={{
          __html: `
      <link
        rel='stylesheet'
        href='https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap'
      >`,
        }}
      ></noscript>
    </Helmet>
  )
}

export default Meta
